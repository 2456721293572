@media (max-width: 1200px) {

}

@media (max-width: 960px) {

}

@media (max-width: 768px) {
  .animate {
    &.fadeUp {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  .container {
    max-width: 480px;
  }
  //
  #top .logo img {
    max-width: 70%;
    margin: 0 auto;
  }
  #top h1 {
    text-align: center;
    font-size: 32px;
  }
  #top h1 span {
    font-size: 40px;
    margin-top: 15px;
  }
  #top .down {
    img {
      max-width: 60px;
      margin: 0 auto;
    }
  }
  #section1 {
    background: none;
    padding-top: 30px;
    .info {
      max-width: 100%;
      padding: 0;
      background: none;
      color: #000;
      .logo {
        display: none;
      }
      &:before {
        display: none;
      }
      h2 {
        font-size: 24px;
      }
      .desc {
        font-size: 16px;
      }
    }

  }
  #section1 .info .flex2 {
    flex-wrap: wrap;

    .item {
      width: 100%;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ddd;
      align-items: center;
      justify-content: center;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
  #section1 .info .flex2 a {
    color: #000;
  }
  #section1 .info .flex1 {
    margin: 0 -5px;
  }
  #section1 .info .flex1 .item {
    padding: 0 5px;
  }
  #section1 .info .flex1 .number {
    font-size: 20px;
  }
  #section1 .info .flex1 .txt {
    font-size: 12px;
  }
  #section1 .info h3 {
    text-align: center;
  }
  #section1 .info .flex3 {
    flex-wrap: wrap;
    .item {
      width: 100%;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  #section1 .info .flex3 .number {
    font-size: 26px;
  }
  #section1 {
    padding: 0 15px;
    .container {
      padding-bottom: 30px;

      border-bottom: 1px solid #adaaaa;
    }
  }
  #section1 .map {
    position: static;
    width: 100%;
  }
  #section2 {
    padding: 50px 0 30px 0;
    h2 {
      font-size: 24px;
    }
    h3 {
      margin-top: 30px;
    }
    .example {
      flex-wrap: wrap;
      padding: 30px 0 0 0;
      .item {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
  #section3 {
    .flex {
      flex-wrap: wrap;

      .item {
        width: 100%;
      }
    }
  }
  #section3 .flex .item > div {
    font-size: 22px;
  }
  #ftr .copyright {
    font-size: 14px;
  }
}



