//@font-face {
//  font-family: BavariaBlack; /* Гарнитура шрифта */
//  src: url('https://esquire.kz/wp-content/themes/esquire_v2/fonts/Bavaria-Black.ttf')  format("truetype");; /* Путь к файлу со шрифтом */
//}

//
//@font-face {
//  font-display: swap;
//  font-family: 'BavariaBlack';
//  src: url('../fonts/Bavaria-Black.woff') format('woff'),
//  url('../fonts/Bavaria-Black.ttf') format('truetype');
//}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

#root {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  line-height: 1.2;
  max-width: 1920px;
  overflow: hidden;
  margin: 0 auto;
  img {
    display: block;
    max-width: 100%;
    will-change: transform;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  // font-family: 'BavariaBlack';
}

//loading
.imagesLoading {
  display: none;
  img {
    width: 0;
  }
}

//effects
.image-cover {
  //overflow: hidden;
  display: block;
  img {
    transition: filter .6s, opacity .6s, transform .6s, box-shadow .3s;
    will-change: transform
  }
  &:hover img {
    transform: scale(1.05);
  }
}

body.loading1 {
  overflow: hidden;
}

//modal
.ReactModalPortal {
  position: relative;
  .closeButton {
    right: 10px;
  }
}

.Modal {
  position: absolute;
  top: 40px;
  padding: 50px;
  max-width: 960px;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  z-index: 9;
}

.video_contain {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

video {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;

  object-fit: cover;
  width: 100vw;
}

.viqeo {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 57%;
  display: inline-block;
  iframe {
    position: absolute;
  }
}

a:focus {
  outline: none;
}

////
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(000, 000, 000, .75);
  }
  to {
    border-right-color: transparent;
  }
}

.typewrite {
  color: #000;
  text-decoration: none;
  > .wrap {
    border-right: 0.05em solid #000;
    padding-right: 10px;
    animation: blinkTextCursor 1000ms steps(2) infinite normal;
  }
}

//top
#top {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .typewrite {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 140px;
    font-weight: bold;
    min-height: 200px;
    line-height: 1;
  }
  h1 {
    text-align: center;
    font-size: 48px;
    font-weight: 400;
    span {
      display: block;
      font-size: 60px;
      text-transform: uppercase;
      margin-top: 25px;
    }
  }
  .down {
    text-align: center;
    img {
      margin: 0 auto;
      cursor: pointer;
      transition: .3s;
      will-change: transform;
      &:hover {
        transform: translateY(10px);
      }
    }

  }
  .logo {
    img {
      max-width: 600px;
    }
  }
}

//section1
#section1 {
  background: url("/img/m1.jpg") top right no-repeat;
  background-size: cover;
  .info {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    max-width: 70%;
    padding: 80px 30px 80px 0;
    position: relative;
    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.7);
      width: 200%;
      position: absolute;
      top: 0;
      left: -200%;
      height: 100%;
    }
    .logo {
      margin: 0 auto;
    }
    h2 {
      font-size: 30px;
    }
    .desc {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 30px;
    }
    .flex1 {
      border-top: 1px solid;
      border-bottom: 1px solid;
      padding: 20px 0;
      justify-content: space-between;
      .number {
        font-size: 30px;
      }
      .txt {
        font-size: 14px;
      }
    }
    .flex2 {
      border-bottom: 1px solid;
      padding: 20px 0;
      justify-content: space-between;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      a {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #fff;
        text-decoration: none;
        img {
          margin-right: 5px;
        }
      }
      .number {
        font-size: 30px;
      }
      .txt {
        font-size: 14px;
      }
    }
    h3 {
      font-weight: normal;
    }
    .flex3 {
      align-items: center;
      .number {
        font-size: 48px;
      }

    }
    .table {
      margin-right: 30px;
      .flex {
        justify-content: center;
        align-items: center;
        div {
          background: rgba(229, 229, 229, 0.3);
          padding: 5px;
          text-align: center;
          margin: 2px;
          width: 100px;
        }
      }
    }
  }
  .container {
    position: relative;
  }
  .map {
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

#section2 {
  padding: 80px 0 30px 0;
  .logo {
    margin: 0 auto;
  }
  h2 {
    text-align: center;
    font-weight: normal;
    font-size: 36px;
  }
  h3 {
    text-align: center;
    margin-top: 50px;
  }
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #000;
      padding: 5px 15px;
      border: 1px solid;
      border-radius: 5px;
      text-decoration: none;
      margin: 0 15px;
      transition: .3s;
      font-size: 24px;
      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
  .example {
    justify-content: space-between;
    padding: 50px 0;
    img {
      margin: 0 auto;
    }
    .title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 10px;
    }
    .desc {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

//section3
#section3 {
  background: url("/img/paper.jpg") top center no-repeat;
  background-size: cover;
  border-top: 5px solid;
  border-bottom: 5px solid;
  padding: 30px 0;
  .logo {
    margin: 0 auto;
  }
  h2 {
    text-align: center;
  }
  .priceWrap {
    text-align: center;
    margin-bottom: 50px;
  }
  .price {
    color: #000;
    padding: 5px 15px;
    border: 1px solid;
    border-radius: 5px;
    text-decoration: none;
    margin: 0 15px;
    transition: .3s;
    font-size: 24px;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  .flex {
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    .link {
      font-weight: normal;
      text-decoration: none;
      font-size: 16px;
      background: #2196f3;
      color: #fff;
      padding: 10px 20px;
      margin-left: 15px;
      border-radius: 3px;
      box-shadow: 2px 2px 3px #a3a3a3;
      &:hover {
        background: #1c79c3;
      }
    }
    .item {
      width: 50%;
      flex-shrink: 0;
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: 26px;
        font-weight: bold;
        span {
          font-size: 16px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
//contacts
#contacts {
  padding: 30px 0;
  .item {
    margin-bottom: 30px;
    span {
      margin-bottom: 5px;
      display: block;
    }
  }
  a {
    color: #000;
    display: block;
    margin-bottom: 5px;
  }
  .container {
    max-width: 600px;
    margin: 0 auto;
  }
  h3 {
    text-align: center;
  }
  .map {
    padding: 15px 0;
    img {
      border: 1px solid #ababab;
      border-radius: 5px;
      padding: 10px;
    }
  }
}
//ftr
#ftr {
  .logo {
    margin: 0 auto 30px auto;
  }
  .copyright {
    background: #262E2E;
    color: #fff;
    text-align: center;
    padding: 15px 0;
  }
}