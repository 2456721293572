*, ::after, ::before {
  box-sizing: border-box;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                         not supported by any browser */
}

.text-center {
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  cursor: pointer;
  transition: .3s;

}

.closeButton:hover {
  opacity: 1;
}

.closeButton:before, .closeButton:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.closeButton:before {
  transform: rotate(45deg);
}

.closeButton:after {
  transform: rotate(-45deg);
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 1;
}

p {
  margin: 0 0 20px;
  &:last-child {
    margin-bottom: 0;
  }
}